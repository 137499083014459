@import url('https://fonts.googleapis.com/css?family=Asap:400,500,700|Fira+Sans:400,500,600,700|Merriweather+Sans:300,400,700|Roboto:300,400,500,700');

/* Overide bootstrap styles */
.dropdown-header {
  font-weight: 500;
}

.App {
  text-align: center;
}

html, body, #root, .application, .indexTab {
  height: 100%;
  font-family: 'Fira Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Merriweather Sans', sans-serif;
  font-family: 'Asap', sans-serif;
}

.application {
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
}

.indexTab {
  padding-top: 16px;
}

.loading-animation {
  margin: 0px auto;
}

/**
 * Translation interface styling
 */
.application .translationInterface, .indexTab .result {
  flex-grow: 1;
}

.application .translationInterface .toolbar {
  margin-top: 8px;
  margin-bottom: 8px;
}

.application .translationInterface .translation {
  font-size: 16px;
}

.translationInterface textarea {
  font-size: 16px;
  font-family: monospace;
}

table.definitionTranslationTable {
  width: 100%;
}

table.definitionTranslationTable, table.definitionTranslationTable tr,
table.definitionTranslationTable th, table.definitionTranslationTable td {
  padding: 3px;
  border: 1px solid #bbb;
}

table.definitionTranslationTable tr:nth-child(odd) {
  background-color: #fff9c4;
}

table.definitionTranslationTable tr:first-child {
  background-color: #ffeb3b;
}

table.definitionTranslationTable tr:first-child th {
  text-align: center;
  padding: 8px 0px;
}

.application .translationInterface .translation .translatedDefinitionSingle {
  padding: 3px;
  padding: 5px 2px 5px 5px;
  border-radius: 3px;
  display: inline-block;
}


.application .translationInterface .translation .translatedDefinition {
  display: inline-block;
  /* padding: 3px; */
  border: 1px solid transparent;
}

.application .translationInterface .translation .translatedDefinition:hover {
  background-color: lightcoral;
  border-color: darkorchid;
  border-radius: 3px;
}

.translatedDefinition .dropdown-menu {
  width: 100%;
}

.translatedDefinition .dropdown-menu > li > a {
  white-space: inherit;
}

.translatedDefinition .dropdown-menu > li[role=presentation] {
  padding: 5px 0px;
}

.translatedDefinition .dropdown-menu > li[role=presentation]:first-child {
  padding: 0px;
}

.translatedDefinition .dropdown-menu > li[role=heading] + li[role=presentation] {
  padding-top: 0px;
}

.translatedDefinition .dropdown-menu > li[role=presentation] + li[role=presentation] {
  border-top: 1px solid #ebebeb;
}

.application .sourceHeader {
    line-height: 1;
    color: cornflowerblue;
}

.application li[role=presentation] + .sourceHeader {
    margin-top: 3px;
    padding-top: 8px;
    border-top: 1px solid #dedede;
}


.indexTab .result {
  overflow-x: auto;
  /* background-color: lightslategrey; */
  /* font-family: monospace; */
}

.indexTab .result [class^=col-] {
  /* background-color: white; */
}

.indexTab .result .withShadow[class^=col-] {
  box-shadow: 0 4px 2px -2px rgba(0,0,0,.4);
}


.indexTab .result ul li {
  /* padding: 3px 0px; */
}

.indexTab .result ul li + li {
  /* border-top: 1px solid #e0e0e0; */
}

.result .result-group-header {
  font-size: 80%;
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #f633a1;
}

table.definitionTranslationTable .translationDropdown {
  text-align: inherit;
  white-space: inherit;
}

.notice {
  margin: 64px 0px;
}

.notice-default {
  color: gray;
  text-shadow: 0px 0px 0px gray;
}

.notice-error {
  color: indianred;
}

.class-numbers-container {
  display: flex;
  flex-wrap: wrap;
}

.class-numbers-container label {
  flex-grow: 1;
  min-width: 20%;
  border-radius: 4px !important;
  border: none;
}


/* Mix elements */
.interface-header {
  font-weight: bold;
  color: #ff33a0;
}

.interface-header.as-visual-separator {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
