strong {
    font-weight: 500;
}

.text-normal {
    font-weight: 400;
}

.semi-bold {
    font-weight: 500;
}

.bold {
    font-weight: 700;
}

.definitionResult {
    display: flex;
    flex-flow: row;
}

.definitionResult .definition {
    flex-grow: 1;
    padding-left: 8px;
}

.definitionResult .definition .textToggler {
    border-radius: 5px;
    padding: 2px 10px;
    border-color: hsla(326, 92%, 58%, .35);
    white-space: normal;
    text-align: inherit;
}

.definitionResult .definition .textToggler .caret {
    color: hsla(326, 92%, 58%, .8);
}

.animatedBackgroundColor {
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}
